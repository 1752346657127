import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components'
import Logo from '../../Assets/images/logo.png';
import { HiMenu } from "react-icons/hi";

function MvNavbar(props) {
const [FixedMenu, setFixedMenu] = useState(false);

const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
        setFixedMenu(true);
    }
    else {
        setFixedMenu(false); 
    }
};
window.addEventListener('scroll', changeNavbarColor);

  return (
     <Container className={FixedMenu ? 'fixed-navbar animate__animated animate__fadeInDown' : ''}>
         <div className="wrapper">
         <div className={`menu ${props.black ? 'dark-links' : ''} `}  >
               <div className="d-flex">
                   <Link to='/home'>
                       <img src={Logo} alt="movicel" />
                   </Link>
               </div>
               <ul className="d-flex">
                  <li><Link to='/'>Home</Link></li>
                  <li><Link to='/mv/services'>Planos</Link></li>
                  <li><Link to='/mv/store'>Telefones & Aparelhos</Link></li>
                  <li><Link to='/mv/offers'>Ofertas</Link></li>
                  <li><Link to='/mv/about'>Porquê Movicel</Link></li>
               </ul>
               <div className="d-flex">
                    <Link to='/account'>
                       <button className='btn'><HiMenu/></button>
                    </Link>
               </div>
           </div>
         </div>
     </Container>
  )
}


const Container = styled.div`
    position:absolute;
    top:0px;
    left:0px;
    z-index:1000;
    width:100%;
    border-bottom:1px solid rgba(194, 194, 194, 0.3);

   .menu{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:7px 10px;

        .d-flex{
            img{
                  max-width:150px;
            }
        }


         ul{
            padding:0px;
            margin:0px;

            li{
                margin: 0px 25px;
                a{
                   text-decoration:unset;
                   color:var(--white);
                   position:relative;
                   font-weight:599;
                }
             }
         }


         .btn{
              width:50px;
              height:50px;
              border:100%;
              display:flex;
              align-items:center;
              justify-content:center;
              background:var(--red);
              border:2px solid var(--red) !important;
              border-radius:100%;
              border:none;
              margin:0px;

              &:hover{
                 background:transparent !important; 
                   svg{
                      fill:var(--red) !important;
                   } 
              }

              svg{
                  min-width:35px;
                  min-height:35px;
                  fill:var(--white);
              }
         }
   }



     .menu.dark-links{
           a{color:var(--dark);}
           .btn{
               background:var(--red);
                  svg{
                      fill:var(--white);
                  }
           }
     }
`;

export default MvNavbar

import React from 'react'
import { styled } from 'styled-components'
import { TfiWorld } from "react-icons/tfi";
import {FaRegAddressCard} from "react-icons/fa";
import { LuBrainCircuit, LuPlane} from "react-icons/lu";
import {GrIntegration} from "react-icons/gr";

function AboutCulture() { 

    const Data = [
        {title:'Integração', icon:<TfiWorld/>, description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat at leo eget accumsan.'},
        {title:'Desenvolvimento', icon:<LuBrainCircuit/>, description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat at leo eget accumsan.'},
        {title:'Cultura', icon:<FaRegAddressCard/>, description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat at leo eget accumsan.'},
        {title:'Expanção', icon:<LuPlane/>, description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat at leo eget accumsan.'}
    ];

  return (
    <Container>
        <div className="wrapper"> 
        <h1 className='mediumn-title'>Nossa <span>Cultura </span></h1>
            <div className="details">
                 <div className="block"> 
                     <div className="row">
                          {Data.map((item,index)=>{
                            return(
                               <div key={index} className="box"> 
                                   <div className="icon"> {item.icon} </div>
                                   <h2> {item.title}</h2>
                                   <p>{item.description}</p>
                               </div>
                            )
                          })}
                     </div>
                 </div>
                 <div className="image">

                 </div>
            </div>
        </div>
    </Container>
  )
}

const Container = styled.div`
   padding:40px  0px;
   background:var(--bluedark); 
   color:var(--white);

   .mediumn-title{
      color:var(--white);
   }

   .details{
      display:flex; 
      align-items:center;
      margin-top:20px;

       .image{
          width:100%;
          max-width:600px;
          min-width:500px;
          height:400px;
          background:var(--white-smoke);
          border-radius:6px;
       }
         
        .box{
            width:49%;

            h2{
                font-size:18px;
                margin:10px 0px;
            }

            p{
                font-size:15px;
                color:silver;
            }

            .icon{
                width:60px;
                height:60px;
                background:var(--white);
                displaY:flex;
                align-items:center;
                justify-content:center;
                border-radius:100%; 
                margin:10px 0px;

                svg{
                    width:30px;
                    height:30px;
                    fill:var(--red) !important;
                    color:var(--red) !important;
                }
           }
        } 
   }
 


`;

export default AboutCulture

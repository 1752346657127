import React from 'react'
import { styled } from 'styled-components'

function AboutFacts() {
    
    const Data = [
        {
            title:'Clientes',
            description:'Inicialmente a empresa utilizou a tecnologia AMPS e mais tarde CDMA; Actualmente ( 2021) toda a rede é GSM.',
            value:'200M+'
        },
        {
            title:'Parceiros',
            description:'Inicialmente a empresa utilizou a tecnologia AMPS e mais tarde CDMA; Actualmente ( 2021) toda a rede é GSM.',
            value:'134K+'
        },
        {
            title:'Serviços efectuados',
            description:'Inicialmente a empresa utilizou a tecnologia AMPS e mais tarde CDMA; Actualmente ( 2021) toda a rede é GSM.',
            value:'90K+'
        },
        {
            title:'Ofertas',
            description:'Inicialmente a empresa utilizou a tecnologia AMPS e mais tarde CDMA; Actualmente ( 2021) toda a rede é GSM.',
            value:'70M+'
        },
        {
            title:'Produtos vendidos',
            description:'Inicialmente a empresa utilizou a tecnologia AMPS e mais tarde CDMA; Actualmente ( 2021) toda a rede é GSM.',
            value:'670M+'
        },
        {
            title:'Visitas no website',
            description:'Inicialmente a empresa utilizou a tecnologia AMPS e mais tarde CDMA; Actualmente ( 2021) toda a rede é GSM.',
            value:'30M+'
        },
    ]

    
  return (
     <Container>
          <div className="wrapper">
             <h1 className='mediumn-title'>Factos reais sobre <span>os nossos </span> serviços </h1>
             <div className="row">
                 {Data.map((item, index)=>{
                  return(
                     <div className="box" key={index}>
                         <h5>{item.title}</h5>
                         <p> {item.description}</p>
                         <h1>{item.value}</h1>
                      </div>
                   )})
                 }
             </div>
          </div>
     </Container>
  )
}


const Container = styled.div`
   margin:50px 0px;

    .row{
        justify-content:space-between;
        padding-top:20px;

        .box{
          width:31%;
          margin-bottom:10px;

             h5{
                font-size:18px;
                font-weight: bold;
             }

             p{
                font-size:15px;
             }

             h1{
                font-weight: bold;
                font-size:25px;
             }
        }
    }
`;

export default AboutFacts

import React from 'react'
import MvNavbar from './Components/MvNavbar'
import AboutHeader from './Components/AboutHeader'
import { styled } from 'styled-components'
import ScrollTop from './Components/ScrollTop';
import Footer from './Components/Footer';
import AboutText from './Components/AboutText';
import AboutFacts from './Components/AboutFacts';
import AboutTeam from './Components/AboutTeam';
import AboutCulture from './Components/AboutCulture';
import AboutCarousel from './Components/AboutCarousel';

const Images = [
  require('../Assets/images/img1.png'),
  require('../Assets/images/img2.png'),
  require('../Assets/images/logo.png'),
  require('../Assets/images/logo.png'),
];

function AboutPage() {
  document.title = "Sobre";
  
  return (
    <Container>
      <MvNavbar active={5} />
      <AboutHeader/>
        <AboutText
           t1='Historico empresarial  &'   t2='valores e cultura'
           description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat at leo eget accumsan. Curabitur cursus tellus eget massa accumsan tempus. Aenean convallis tristique risus, id bibendum dolor cursus auctor. Praesent viverra mattis leo, eu iaculis risus consequat sit amet. Orci varius natoque penatibus et magnis dis parturient montes, Vivamus elementum
           turpis egestas. Etiam varius eget nibh nec   rhoncus cursus ipsum. Etiam placerat varius ultricies. Phasellus ac nulla erat. Maecenas non magna non diam dapibus rutrum. Sed eu orci eros. Curabitur accumsan ex dolor, et consequat eros tincidunt quis.'
           image={Images[0]}
         />  
        <AboutText
           reverse
           t1='Comunicação &'   t2='Inovação'
           description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat at leo eget accumsan. Curabitur cursus tellus eget massa accumsan tempus. Aenean convallis tristique risus, id bibendum dolor cursus auctor. Praesent viverra mattis leo, eu iaculis risus consequat sit amet. Orci varius natoque penatibus et magnis dis parturient montes, Vivamus elementum
           turpis egestas. Etiam varius eget nibh nec   rhoncus cursus ipsum. Etiam placerat varius ultricies. Phasellus ac nulla erat. Maecenas non magna non diam dapibus rutrum. Sed eu orci eros. Curabitur accumsan ex dolor, et consequat eros tincidunt quis.'
           image={Images[1]}
         /> 
            <Cover></Cover> 
         <AboutFacts/>
         <AboutTeam />
         <AboutCulture />
         <AboutCarousel />
      <Footer />
      <ScrollTop />
    </Container>
  )
}


const Container = styled.div`
   p{
      font-size:16px;
      font-weight:550; 
   }
`;

const Cover = styled.div`
    margin:20px 0px;
    width:100%;
    min-height:500px;
    max-height:600px;
    background:var(--white-smoke);

    img{
        width:100%;
        height:100%;
    }
`;

 



export default AboutPage

import React, { useState } from 'react'
import { styled } from 'styled-components'
import { TbCircleCaretUp, TbCircleCaretDown  } from "react-icons/tb";


function ContactQuestions() {

const Questions = [
    {title:'Como efectuar chamadas usando um plano Movicel ?'},
    {title:'Como efectuar chamadas usando um plano Movicel ?'},
    {title:'Como efectuar chamadas usando um plano Movicel ?'},
]

const [activeOption, SetActiveOption] = useState(0);


const ChangeAccordion = (e)=>{
    if(e === activeOption){
        SetActiveOption(null);
    }else{
        SetActiveOption(e)
    }
}

  return (
      <Container>
          <div className="wrapper">
               <h1 className="mediumn-title">Perguntas frequentes <span>!</span> </h1>
               <ul>
                 {Questions.map((item, index)=>{
                     return(
                      <li className={`${activeOption === index ? 'active' : '' }`} key={index} onClick={()=>ChangeAccordion(index)} >
                        <div className="header"> 
                           <div className="small-title">{item.title}</div>
                             <div className="toggle">
                                 {activeOption === index ? <TbCircleCaretUp/> : <TbCircleCaretDown/> }
                             </div> 
                         </div>
                         <div className="content">
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime, voluptates consequatur incidunt reiciendis fugiat, obcaecati assumenda vitae ea doloremque aliquam quia, laborum eius eum ex eaque deleniti ratione soluta. Recusandae cumque veniam maxime iusto, deserunt, vero repellendus saepe quae mollitia fuga modi minus? Non facere sapiente, distinctio aperiam facilis animi molestias dicta eum neque? Voluptatem architecto similique nobis tenetur laudantium reprehenderit. Maiores voluptate repellendus odio ab molestiae dolor porro consequuntur. Nulla ex ea tempore, accusamus quae repellendus quas magni consectetur libero harum corrupti neque odio amet perspiciatis omnis. Dolore illo, nam voluptas pariatur harum cupiditate quis cum veritatis quidem tenetur!</p>
                         </div>
                      </li>
                     )
                 })}
               </ul>
          </div>
      </Container>
  )
}

const Container = styled.div`
   padding:20px;


    ul{
        padding:0px; 
        margin:20px 0px;
        border-top:1px solid var(--white-smoke);


        li.active{
              svg{
                  color:var(--red);
              }

              .content{
                   display:block;
              }
        }

        li{
            width:100%;
            border:1px solid var(--white-smoke);
            border-top:0px;
            margin:0px 0px; 


              .header{
                 display:flex;
                 align-items:center;
                 justify-content:space-between;
                 padding:10px 20px;
                 cursor:pointer;

                    .toggle{
                          cursor:pointer;
                           
                          svg{
                              min-width:30px;
                              min-height:30px;
                          }
                    }
              }

              .content{
                  display:none;
                  padding:10px 20px;
                  border-top:1px solid var(--white-smoke);
                  font-size:15px;
                  color:grey;
              }

              .

        }
    }
 `;

export default ContactQuestions

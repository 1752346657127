import React from 'react'
import { Link } from 'react-router-dom'

function NotFounded() {
  return (
    <div>
        <h1>Not founded</h1>
        <br />
        <Link to="/">back now bro</Link>
    </div>
  )
}

export default NotFounded 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Intro from './Intro';
import AboutPage from './Pages/AboutPage';
import NotFounded from './Pages/NotFounded';
import Store from './Pages/Store';
import ContactPage from './Pages/ContactPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Intro />} /> 
        <Route path="/store" element={<Store />} /> 
        <Route path="/contacts" element={<ContactPage />} /> 
        <Route path="*" element={<NotFounded />} /> 
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </Router>
  );
}

export default App;

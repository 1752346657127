import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const Pages = [
    {
      image:'',
      name:'',
      link:''
    }
  ]

  
function Intro() {
    return (
        <Container>

        </Container>
      );
}


const Container = styled.div`
    width:100%; 
    padding:20px; 
    background:  var(--red); 
    min-height: 100vh; 
   
  }
  
`;

export default Intro

import React from 'react'
import { Form } from 'react-bootstrap';
import { styled } from 'styled-components'
import {BsCheck} from "react-icons/bs";
import Img from '../../Assets/images/shape.png'
import { FaArrowRight } from "react-icons/fa";
import SocialLinks from './SocialLinks';
import { Link } from 'react-router-dom';


function ContactHeader() {
  return (
     <Container style={{backgroundImage:`url('${Img}')`}}>
         <div className="wrapper">
               <div className="space">
                    <div className="block-text animate__animated animate__fadeInLeft"> 
                         <h1 className='large-title'> Fale com os melhores dos  melhores<span> na aréa</span></h1>
                         <ul>
                             <li><div className="check"><BsCheck/></div> Planos de voz e internet para todos efeitos </li>
                             <li><div className="check b"><BsCheck/></div>Aparelhos & Telefones </li>
                             <li><div className="check"><BsCheck/></div> Empresas e Agentes de todas as dimenções </li>
                             <li><div className="check b"><BsCheck/></div> Ofertas e Suporte técnico </li>
                         </ul>
                         <Link to="/about">
                            <button className="btn btn-icon bg-primary btn-circle">Saiba mais <div className="icon"><FaArrowRight/></div></button>
                         </Link>
                    </div>
                    <div className="contact-form animate__animated animate__fadeInRight">
                          <Form>
                              <div className="space-form">
                                  <Form.Label>Primeiro nome</Form.Label>
                                  <Form.Control placeholder='ex: Carlos' />
                              </div>
                              <div className="space-form">
                                  <Form.Label>Ultimo nome</Form.Label>
                                  <Form.Control placeholder='ex: Mateus' />
                              </div>
                              <div className="space-form">
                                  <Form.Label>Email proprio</Form.Label>
                                  <Form.Control placeholder='ex: carlosmateus@movicel.co.ao' />
                              </div>
                              <div className="space-form">
                                  <Form.Label>Telefone</Form.Label>
                                  <Form.Control placeholder='ex: +244 990 000 111' />
                              </div>
                              <div className="space-form">
                                  <Form.Label>Mensagem</Form.Label>
                                  <Form.Control as="textarea" rows="14"  placeholder='...' />
                              </div>
                             <div className="flex-end">
                                <button className="btn bg-primary text-light btn-circle">Enviar</button>
                             </div>
                          </Form>
                    </div>
               </div>
         </div> 
         <SocialLinks/>
     </Container>
  )
}


const Container = styled.div`
    width:100%;
    min-height:700px;
    padding-top:100px;
    background-color:var(--bluedark);
    margin-bottom:20px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    position:relative;
 

    .block-text{  
        padding-top:80px;  

          .large-title{
              max-width:700px;
          }

          

          ul{
             padding:20px 0px;
             margin:0px;

               li{
                  margin:15px 0px;
                  color:var(--white);
                  display:flex;
                  align-items:center;

                    .check{
                       margin-right:10px;
                       width:20px;
                       height:20px;
                       border-radius:100%;
                       display:flex;
                       align-items:center;
                       justify-content:center;
                       background:var(--red);
                         
                    }
               }

          }
    }

     
    .contact-form{
          min-width:490px;
          height:650px;
          background:var(--white);
          padding:30px 20px;
          margin-bottom:-150px !important;
          box-shadow:0px 3px 10px rgba(0,0,0,0.1);
          border-radius:10px;

            
          .flex-end{
              display:flex; 
              justify-content:flex-end;
              width:100%;
          }

            .space-form{
                   display:flex; 
                   justify-content:space-between;
                   margin:10px;

                    input, textarea{
                         max-width:300px;
                         border:none;
                         background:#edf5fd;
                    }
            }
    }

`;

export default ContactHeader

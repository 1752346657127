import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const Links = [
    {name:'Facebook', link:'#'},
    {name:'Instagram', link:'#'},
    {name:'Youtube', link:'#'},
    {name:'Twitter', link:'#'},
]

function SocialLinks() {
  return (
     <Container>
        {Links.map((item, index)=>{
            return <li key={index}><a href={item.link} > {item.name} </a></li>
        })}
     </Container>
  )
}


const Container = styled.ul`
   position:absolute;
   bottom:200px;
   left:-140px;
   display:flex;  
   align-items:center;
   list-style:none;
   height:30px; 
   transform:rotate(-90deg);

     li{   
        margin-right:20px;
           
          a{
            text-decoration:none;
            font-size:16px;
            color:var(--white);
          }
     }
`;

export default SocialLinks

import React from 'react'
import MvNavbar from './Components/MvNavbar'
import { styled } from 'styled-components'
import Store_Sidebar from './Components/Store_Sidebar';
import Store_products from './Components/Store_products';
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop';

function Store() {
 const productsData = [];

  return (
    <div>
        <MvNavbar black />
        <div className="wrapper">
            <br /><br /> 
            <br /><br /> 
            <Container>
                <Store_Sidebar />
               <Store_products /> 
            </Container>
        </div>
        <Footer/>
        <ScrollTop />
    </div>
  )
}


const Container = styled.div`
    width:100%;
    display:flex;
    margin:20px 0px;
`;

export default Store

import React, { useState } from 'react'
import { styled } from 'styled-components'
import { BiStoreAlt,BiSearchAlt,BiFilterAlt,BiHeart } from "react-icons/bi";
import { Badge, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

const items = [
    {name:'Iphone 13 pro Max', price:'Kz 23.000.00', colors:['#FF8600', '#27187E', '#7B8C46', '#3F88C5'], type:0 },
    {name:'Sansung Galax Fold', price:'Kz 23.000.00', colors:['#8F2D56', '#463F3A', '#3F88C5', '#9C6644'], type:0 },
    {name:'Macbook pro 13', price:'Kz 23.000.00', colors:['#011627', '#28262B', '#FFC857', '#979DAC'], type:1 },
    {name:'Nokia mobile phone', price:'Kz 23.000.00', colors:['#27187E', '#140F2D', '#979DAC', '#7B8C46'], type:0 },
    {name:'Ipad Track', price:'Kz 23.000.00', colors:['#0B132B', '#E71D36', '#28262B', '#011627'], type:2 },
    {name:'Auriculares sem fio', price:'Kz 23.000.00', colors:['#3A506B', '#6D597A', '#6D4C3D', '#33415C'], type:0 },
    {name:'Teclado digital', price:'Kz 23.000.00', colors:['#E71D36', '#463F3A', '#A9A29C', '#8F2D56'], type:0 },
    {name:'mouse sem fio', price:'Kz 23.000.00', colors:['#A9A29C', '#011627', '#27187E', '#FFC857'], type:0 },
    {name:'Iphone 13 pro Max', price:'Kz 23.000.00', colors:['#FF8600', '#27187E', '#7B8C46', '#3F88C5'], type:0 },
    {name:'Sansung Galax Fold', price:'Kz 23.000.00', colors:['#8F2D56', '#463F3A', '#3F88C5', '#9C6644'], type:0 },
    {name:'Macbook pro 13', price:'Kz 23.000.00', colors:['#011627', '#28262B', '#FFC857', '#979DAC'], type:1 },
    {name:'Nokia mobile phone', price:'Kz 23.000.00', colors:['#27187E', '#140F2D', '#979DAC', '#7B8C46'], type:0 },
    {name:'Ipad Track', price:'Kz 23.000.00', colors:['#0B132B', '#E71D36', '#28262B', '#011627'], type:2 },
    {name:'Auriculares sem fio', price:'Kz 23.000.00', colors:['#3A506B', '#6D597A', '#6D4C3D', '#33415C'], type:0 },
    {name:'Teclado digital', price:'Kz 23.000.00', colors:['#E71D36', '#463F3A', '#A9A29C', '#8F2D56'], type:0 },
    {name:'mouse sem fio', price:'Kz 23.000.00', colors:['#A9A29C', '#011627', '#27187E', '#FFC857'], type:0 },
    {name:'Iphone 13 pro Max', price:'Kz 23.000.00', colors:['#FF8600', '#27187E', '#7B8C46', '#3F88C5'], type:0 },
    {name:'Sansung Galax Fold', price:'Kz 23.000.00', colors:['#8F2D56', '#463F3A', '#3F88C5', '#9C6644'], type:0 },
    {name:'Macbook pro 13', price:'Kz 23.000.00', colors:['#011627', '#28262B', '#FFC857', '#979DAC'], type:1 },
    {name:'Nokia mobile phone', price:'Kz 23.000.00', colors:['#27187E', '#140F2D', '#979DAC', '#7B8C46'], type:0 },
    {name:'Ipad Track', price:'Kz 23.000.00', colors:['#0B132B', '#E71D36', '#28262B', '#011627'], type:2 },
    {name:'Auriculares sem fio', price:'Kz 23.000.00', colors:['#3A506B', '#6D597A', '#6D4C3D', '#33415C'], type:0 },
    {name:'Teclado digital', price:'Kz 23.000.00', colors:['#E71D36', '#463F3A', '#A9A29C', '#8F2D56'], type:0 },
    {name:'mouse sem fio', price:'Kz 23.000.00', colors:['#A9A29C', '#011627', '#27187E', '#FFC857'], type:0 },
    {name:'Iphone 13 pro Max', price:'Kz 23.000.00', colors:['#FF8600', '#27187E', '#7B8C46', '#3F88C5'], type:0 },
    {name:'Sansung Galax Fold', price:'Kz 23.000.00', colors:['#8F2D56', '#463F3A', '#3F88C5', '#9C6644'], type:0 },
    {name:'Macbook pro 13', price:'Kz 23.000.00', colors:['#011627', '#28262B', '#FFC857', '#979DAC'], type:1 },
    {name:'Nokia mobile phone', price:'Kz 23.000.00', colors:['#27187E', '#140F2D', '#979DAC', '#7B8C46'], type:0 },
    {name:'Ipad Track', price:'Kz 23.000.00', colors:['#0B132B', '#E71D36', '#28262B', '#011627'], type:2 },
    {name:'Auriculares sem fio', price:'Kz 23.000.00', colors:['#3A506B', '#6D597A', '#6D4C3D', '#33415C'], type:0 },
    {name:'Teclado digital', price:'Kz 23.000.00', colors:['#E71D36', '#463F3A', '#A9A29C', '#8F2D56'], type:0 },
    {name:'mouse sem fio', price:'Kz 23.000.00', colors:['#A9A29C', '#011627', '#27187E', '#FFC857'], type:0 },
];

function Store_products_container({ currentItems }) {
  return (
     <div>
          <div className="header">
            <div className="d-flex">
                <BiStoreAlt/> <div className="small-title">Produtos Movicel</div>
            </div>
            <div className="d-flex">
                <Form>
                    <BiSearchAlt/>
                    <Form.Control placeholder='pesquisar ...' />
                </Form>
                <button className="btn"><BiFilterAlt/></button>
            </div>
          </div>
          <div className="products-container">
                {currentItems &&
                    currentItems.map((item) => (
                        <div className='product-card'>
                                <div className="image">
                                    <div className="like"><BiHeart/> </div>
                                </div>
                               <div className="details">
                                    <Link to="#"><h3>{item.name}</h3></Link>
                                    <div className="colors">
                                        {item.colors.map((cl)=>(
                                            <div className="color" style={{background:`${cl}`}} ></div>
                                        ))}
                                    </div>
                                    <div className="price"><strong>{item.price}</strong></div>
                                    {item.type*1 === 0 ? <Badge bg='warning'>Novo</Badge> : <></>}
                                    {item.type*1 === 1 ? <Badge bg='success'>Tendencia</Badge> : <></>}
                                    {item.type*1 === 2 ? <Badge bg='danger'>Promoção</Badge> : <></>}
                               </div>
                        </div>
                ))}
          </div>
     </div>
  )
}



function Store_products() { 
  const [itemOffset, setItemOffset] = useState(0); 
  const endOffset = itemOffset + 12;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / 12);
 
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 12) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    
    <Container className='animate__animated animate__fadeInRight'>
        <div className='products-paginated' >
        <Store_products_container currentItems={currentItems} />
        <div className="paginate">
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
        </div>
        </div> 
    </Container>
  );
}




const Container = styled.div`
  padding-left:30px;
  diaplay:block;
  width:100%; 

    .products-paginated{
        display:block;
        width:100%;

        .header{
              width:100%; 
              display:flex;
              justify-content:space-between;
              align-items:center;

              .d-flex{
                align-items:center; 

                svg{
                    min-width:20px;
                    min-height:20px;
                    margin-right:10px;
                 }
              }

              form{
                display:flex;
                background:var(--white);
                box-shadow:0px 3px 10px rgba(0,0,0,0.2);
                padding:6px 20px;
                align-items:center;
                border-radius:30px;  
                height:50px;
                overflow:hidden;

                svg{
                    min-width:20px;
                    min-height:20px;
                }

                 input{
                     border:none;
                 }
              }

              .btn{
                display:flex;
                background:var(--white);
                box-shadow:0px 3px 10px rgba(0,0,0,0.2); 
                align-items:center;
                justify-content:center;
                border-radius:30px; 
                width:50px;
                min-width:50px;
                height:50px;
                margin-left:10px;

                  svg{
                       min-width:20px;
                       min-height:20px;
                       margin:0px;
                  }
              }
        }

         .products-container{
              margin:20px 0px;
              display:flex;
              flex-wrap:wrap;
              justify-content:space-around;
              width:100%;

               .product-card{
                    width:24%;
                    min-width:200px;
                    min-height:350px; 
                    margin:10px 0px; 

                     .details{ 
                        padding:10px;

                        .price{
                             font-size:15px !important;
                        }

                       a{
                           text-decoration:none;
                         h3{
                            font-size:18px !important;
                            color:grey;
                         }
                       }

                        .badge{
                            padding:7px 15px;
                            margin-top:10px;
                        }
                     }

                      .image{
                          height:190px;
                          width:100%;
                          background:var(--white-smoke);
                          position:relative;


                            .like{
                                width:40px;
                                height:40px;
                                display:flex;
                                background:var(--white);
                                box-shadow:0px 3px 10px rgba(0,0,0,0.2); 
                                align-items:center;
                                justify-content:center;
                                border-radius:30px; 
                                position:absolute;
                                top:10px;
                                right:10px; 
                                cursor:pointer;
                                opacity:0;
                            }

                            &:hover .like{
                                opacity:1;
                            }
                      }


                      .colors{
                           display:flex;
                           align-items:center;
                           margin:10px 0px;

                             .color{
                                  width:20px;
                                  height:20px;
                                  margin-right:10px; 
                                  border:2px solid var(--white-smoke);
                                  border-radius:100%;
                             }
                      }
               }
         }
    }

`;

export default Store_products

import React from 'react'
import { Form } from 'react-bootstrap';
import RangeSlider from 'react-range-slider-input';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

function Store_Sidebar() {
  return (
    <Sidebar className=' animate__animated animate__fadeInLeft'>
          <div className="small-title">Categorias</div>
        <Form>
           <div className="form-price mt-4">
               <div className="txt">MIN</div>
               <Form.Control type='number' value='0' />
               <div className="coin">$</div>
           </div>
           <div className="form-price mt-4">
               <div className="txt">MIN</div>
               <Form.Control type='number' value='0' />
               <div className="coin">$</div>
           </div>
           <div className="mt-4">
                <RangeSlider id='range-slider' />
           </div>
           <div className="mt-4">
               <Form.Check   type="checkbox"  id="custom-switch-1"  label="Smartphones" />
           </div>
           <div className="mt-2">
               <Form.Check   type="checkbox"  id="custom-switch-2"  label="Tablets" />
           </div>
           <div className="mt-2">
               <Form.Check   type="checkbox"  id="custom-switch-3"  label="Computadores" />
           </div>
           <div className="mt-2">
               <Form.Check   type="checkbox"  id="custom-switch-4"  label="Acessórios" />
           </div>
           <button className="btn mt-4 bg-primary btn-circle text-light">Aplicar filtros</button>
        </Form>
         <Link>
            <div className="post-banner lg"></div>
         </Link>
        <Link>
          <div className="post-banner"></div>
        </Link>
    </Sidebar>
  )
}

const Sidebar = styled.div`
     width:100%;
     max-width:300px;
     min-width:300px;
     margin-top:10px;

     .btn{
          width:100%;
     }



     .form-price{
          display:flex; 
          border-radius:7px;
          background:var(--white);
          border:1px solid var(--white-smoke);

          input{
             border:none;
             border-left:1px solid var(--white-smoke);
             border-radius:0px;  
          }

          .txt{
            padding:6px 10px;
            display:flex; 
            align-items:center;
          }

            .coin{
                background:var(--white-smoke);
                 padding:6px 8px;
                 display:flex; 
                 align-items:center;
            }
     }

     .post-banner{
          min-height:200px;
          width:100%;
          margin:015px 0px; 
          background:var(--white-smoke);
     }

     .post-banner.lg{
        min-height:300px;
     }
`;

export default Store_Sidebar;
